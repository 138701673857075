import { useRouter } from 'next/router'

//USE THIS AS TEMPLATE
const content: any = {
  'en-US': {
    GENERAL_CHECKOUT: 'Checkout',
  },
  es: {
    GENERAL_CHECKOUT: 'Checkout',
  },
  'en-GB': {
    GENERAL_CHECKOUT: 'Checkout',
  },
  'en-IN': {
    GENERAL_CHECKOUT: 'Checkout',
  },
  'es-ES': {
    GENERAL_CHECKOUT: 'Checkout',
  },
}

//REACT CUSTOM HOOK, TO BE IMPORTED ONLY IN REACT FUNCTIONAL COMPONENTS
const useTranslation = () => {
  const { locale }: any = useRouter()
  return content[locale] || content['en-IN']
}

export default useTranslation

export const MIN_BODY_LENGTH_ERROR =
  'Your review needs to be at least 5 characters long'
// export const PROMO_ERROR = 'Promotion code is not valid'
export const PROMO_ERROR = 'Oops! This promo code is not valid.'
export const DETAILS_SUCCESS = 'Success! Your details have been updated!'
export const DETAILS_ERROR = 'Woops! Something went wrong!'
export const ADDRESS_BOOK_TITLE = 'Saved Address'
export const DETAILS_SUBTITLE =
  'Feel free to edit any of your details below so your account is totally up to date.'
export const EMPTY_ADDRESS = 'Oh-no! Your address book is empty.'
export const ADD_ADDRESS = 'Add New Delivery Address'
export const GENERAL_EDIT = 'Edit'
export const GENERAL_DELETE = 'Delete'
export const GENERAL_DEFAULT_DELIVERY_ADDRESS = 'Default delivery address'
export const GENERAL_DEFAULT_BILLING_ADDRESS = 'Default billing address'
export const GENERAL_TITLE = 'Title'
export const GENERAL_MISS = 'Miss'
export const GENERAL_MR = 'Mr'
export const GENERAL_MRS = 'Mrs'
export const GENERAL_FIRST_NAME = 'Fullname'
export const GENERAL_LAST_NAME = 'Last name'
export const GENERAL_ADDRESS = 'Address'
export const GENERAL_LANDMARK = 'Landmark'
export const GENERAL_ADDRESS_LINE = 'Address line'
export const GENERAL_ADDRESS_LINE1 = 'Address line 1'
export const GENERAL_ADDRESS_LINE2 = 'Address line 2'
export const GENERAL_CANCEL = 'Cancel'
export const GENERAL_CITY = 'Town / city'
export const GENERAL_POSTCODE = 'Postcode'
export const GENERAL_COUNTRY = 'Country'
export const GENERAL_PHONE = 'Phone'
export const GENERAL_BIRTHDATE = 'Birthdate'
export const GENERAL_IS_DEFAULT_DELIVERY_ADDRESS = 'Is Default Delivery Address'
export const GENERAL_IS_DEFAULT_BILLING_ADDRESS = 'Is Default Billing Address'
export const GENERAL_SMS = 'SMS'
export const GENERAL_EMAIL = 'Email'
export const GENERAL_POST = 'Post'
export const GENERAL_MOBILE_NUMBER = 'Mobile Number'
export const GENERAL_WANT_RECEIVE_OFFERS = 'I want to receive offers'
export const GENERAL_NOT_WANT_RECEIVE_OFFERS = 'I don’t want to receive offers'
export const CONTACT_PREFERENCES_TITLE =
  'Please note, when you update your preferences they will be saved but they won’t be reflected right away.'
export const CONTACT_PREFERENCES_SUBTITLE =
  'Receive emails and texts containing tips, guidance, offers and news on new products and services.'
export const GENERAL_SAVE_CHANGES = 'Update Details'
export const MY_ACCOUNT_TITLE = 'My Account'
export const MY_DETAIL_TEXT =
  'Feel free to edit any of your details below so your account is totally up to date.'
export const MY_ORDERS_TEXT =
  'Check the status of recent orders, manage returns, and download invoices.'
export const GENERAL_MY_ORDERS = 'My Orders'
export const GENERAL_MY_RETURNS = 'My Returns'
export const GENERAL_MY_DETAILS = 'My Profile'
export const GENERAL_CONTACT_PREFERENCES = 'Contact Preferences'
export const ORDER_HISTORY_TITLE = 'Order history'
export const GENERAL_RECENT_ORDERS = 'Recent orders'
export const GENERAL_ORDER_NUMBER = 'Order number'
export const GENERAL_DATE_PLACED = 'Date placed'
export const GENERAL_TRACKING_LINK = 'Tracking link'
export const GENERAL_VIEW_PRODUCT = 'View Product'
export const GENERAL_ADD_TO_BASKET = 'Add'
export const GENERAL_MOVE_TO_BAG = 'Move to Bag'
export const GENERAL_ADD_TO_BAG = 'Add to Bag'
export const GENERAL_CHANGE_FREE_GIFT = 'Change Free Gift'
export const GENERAL_QUICK_VIEW = 'Quick View'
export const GENERAL_TOTAL = 'Total'
export const GENERAL_REMOVE = 'Remove'
export const GENERAL_ORDER_PLACED_ON = 'Order placed on'
export const GENERAL_TOTAL_AMOUNT = 'Total amount'
export const GENERAL_VIEW_ORDER = 'View Order'
export const GENERAL_VIEW_INVOICE = 'View Invoice'
export const RETURN_FOR_ORDER = 'for order'
export const GENERAL_BUY_AGAIN = 'Buy Again'
export const GENERAL_DELIVERED = 'Delivered'
export const GENERAL_ON_TEXT = 'on'
export const GENERAL_CATALOG = 'Catalog'
export const WISHLIST_SUCCESS_MESSAGE = 'Item was added in the cart'
export const GENERAL_CONTINUE_SHOPPING = 'Continue Shopping'
export const RETURN_ORDER_TITLE = 'Return history'
export const RETURN_ORDER_TEXT = 'Check the products you’ve returned'
export const ORDER_STATUS_OUT_FOR_DELIVERY = 'Out For Delivery'
export const ORDER_STATUS_CANCELLED = 'Cancelled'
export const WISHLIST_TITLE = 'Wishlist'
export const GENERAL_WISHLIST = 'My Wishlist'
export const WISHLIST_SUB_TITLE = 'You haven’t wished for anything yet.'
export const UPI_NO_DATA_SUB_TITLE = 'You haven’t saved any UPI ID yet.'
export const UPI_NO_DATA_SIDEBAR_MESSAGE = 'Uh-oh, you don’t have any UPIs here'
export const CARD_NO_DATA_SUB_TITLE = 'You haven’t saved any card yet.'
export const CARD_NO_DATA_SIDEBAR_MESSAGE =
  'Uh-oh, you don’t have any card here'
export const WISHLIST_SIDEBAR_MESSAGE =
  'Uh-oh, you don’t have any items in here'
export const GENERAL_SHOPPING_CART = 'Shopping cart'
export const GENERAL_BAG = 'Bag'
export const CLOSE_PANEL = 'Close panel'
export const SUBTOTAL_INCLUDING_TAX = 'Subtotal'
export const GENERAL_SUBTOTAL = 'Subtotal'
export const GENERAL_TAXES = 'Taxes'
export const CALCULATED_AT_CHECKOUT = 'Calculated at checkout'
export const GENERAL_SHIPPING = 'Shipping Charges'
export const GENERAL_CONVENIENCE = 'Convenience Fee'
export const GENERAL_DISCOUNT = 'Discount'
export const GENERAL_CHECKOUT = 'Checkout'
export const GENERAL_PLACE_ORDER = 'Place Order'
export const MOVED_TO_WISHLIST = 'This item was moved to your wishlist.'
export const REMOVED_FROM_BAG = 'This item was removed from your bag.'
export const GENERAL_OR_TEXT = 'or'
export const APPLY_PROMOTION = 'Enter Coupon Code'
export const APPLY_PROMOTION_SUCCESS_MESSAGE = 'has been applied successfully!!'
export const GENERAL_APPLY_TEXT = 'Apply'
export const ENTER_POSTCODE = 'Enter your postcode'
export const GENERAL_DISTANCE_MILES = 'miles'
export const NORMAL_OPENING_HOURS = 'Normal Opening Hours'
export const GENERAL_DELIVERY_METHOD = 'Delivery Method'
export const GENERAL_SHIPPING_METHOD = 'Shipping Method'
export const GENERAL_DELIVERY_ADDRESS = 'Delivery Address'
export const GENERAL_PAYMENT_METHOD = 'Payment Method'
export const GENERAL_PAYMENT = 'Payment'
export const ADDRESS_OF_YOUR_CHOICE = 'to an address of your choice'
export const IN_STORE_OR_COLLECT_PLUS = 'in store or using Collect+'
export const GENERAL_SELECT_COUNTRY = 'Select country'
export const GENERAL_CONFIRM = 'Confirm'
export const BILLING_ADDRESS_SAME_AS_DELIVERY_ADDRESS =
  'My billing and delivery address are the same'
export const SHIPPING_INFORMATION = 'Shipping information'
export const BILLING_INFORMATION = 'Billing information'
export const BTN_DELIVER_TO_THIS_ADDRESS = 'Deliver to this address'
export const BTN_CONFIRM_PURCHASE = 'Confirm Purchase'
export const GENERAL_ORDER_SUMMARY = 'Order summary'
export const ITEMS_IN_YOUR_CART = 'Items in your cart'
export const GENERAL_CONFIRM_ORDER = 'Confirm order'
export const GUEST_LATEST_PROMOTIONS_OFFERS_INFORMATION =
  "To get our latest promotions, exclusive offers, new launches and more, let us know how you'd like us to keep you updated"
export const BTN_CHECKOUT_SECURELY = 'Checkout securely'
export const LOG_IN = 'Log in'
export const GUEST_CHECKOUT = 'Guest Checkout'
export const NEW_CUSTOMER = 'New customer?'
export const CUSTOMER_ERROR_MESSAGE =
  'No account has been found with this email/password'
export const GENERAL_FREE = 'FREE'
export const CARDHOLDER_NAME = 'Cardholder Name'
export const CARD_NUMBER = 'Card Number'
export const CARD_EXPIRY_DATE = 'Expires'
export const CARD_CVC = 'CVC'
export const ADDRESS_COMPANY_OPTIONAL = 'Company (Optional)'
export const ADDRESS_COMPANY = 'Company'
export const ADDRESS_STREET_HOUSE_NUMBER = 'Street and House Number'
export const ADDRESS_APARTMENT_SUITES = 'Apartment, Suite, Etc. (Optional)'
export const ADDRESS_POSTAL_CODE = 'Postal Code'
export const BTN_CONTINUE = 'Continue'
export const ADD_PAYMENT_METHOD = 'Add Payment Method'
export const USE_DIFFERENT_SHIPPING_ADDRESS = 'Use a different shipping address'
export const ADDRESS_SAME_AS_BILLING = 'Same as billing address'
export const BTN_ADD_SHIPPING_ADDRESS = 'Add Shipping Address'
export const GENERAL_BAGS = 'Bags'
export const GENERAL_TEES = 'Tees'
export const GENERAL_OBJECTS = 'Objects'
export const GENERAL_HOME_GOODS = 'Home Goods'
export const GENERAL_ACCESSORIES = 'Accessories'
export const GENERAL_WHO_WE_ARE = 'Who we are'
export const GENERAL_SUSTAINABILITY = 'Sustainability'
export const GENERAL_PRESS = 'Press'
export const GENERAL_CAREERS = 'Careers'
export const GENERAL_TERMS_AND_CONDITIONS = 'Terms & Conditions'
export const GENERAL_PRIVACY = 'Privacy'
export const GENERAL_CONTACT = 'Contact'
export const GENERAL_RETURNS = 'Returns'
export const GENERAL_WARRANTY = 'Warranty'
export const GENERAL_SECURE_PAYMENT = 'Secure Payments'
export const GENERAL_FAQ = 'FAQ'
export const GENERAL_FIND_A_STORE = 'Find a store'
export const SIGN_UP_FOR_NEWSLETTER = 'Sign up for our newsletter'
export const SIGN_UP_TEXT =
  'The latest deals and savings, sent to your inbox weekly.'
export const GENERAL_EMAIL_ADDRESS = 'Email address'
export const BTN_SIGN_UP = 'Sign up'
export const BTN_SUBMIT_TEXT = 'Submit'
export const COPYRIGHT_FOOTER_INFO =
  '© 2024 Damensch Apparel Pvt. Ltd. All Rights Reserved'
export const GENERAL_COOKIE_TEXT =
  'This site uses cookies to improve your experience. By clicking, you agree to our Privacy Policy.'
export const BTN_ACCEPT_COOKIE = 'Accept cookies'
export const GENERAL_RECENTLY_VIEWED = 'Recently viewed'
export const BTN_SIGN_OUT = 'Sign out'
export const GENERAL_LOGIN = 'Login'
export const GENERAL_REGISTER = 'Register'
export const GENERAL_WORKFLOW_TITLE = 'Workflow'
export const SELECT_CURRENCY = 'Select currency'
export const SELECT_LANGUAGE = 'Select language'
export const GENERAL_ITEM_IN_CART = 'items in cart, view bag'
export const BTN_SEARCH = 'Search'
export const SEARCH_PLACEHOLDER = 'Start typing to search'
export const GENERAL_CLOSE = 'Close'
export const GENERAL_BACK = 'Back'
export const VALIDATION_PASSWORD_MUST_MATCH = 'Passwords must match'
export const PRODUCT_INFORMATION = 'Product information'
export const PRODUCT_DESCRIPTION = 'Description'
export const PRODUCT_OPTIONS = 'Product options'
export const BTN_SEE_MORE_DETAILS = 'See more details'
export const YOUR_BUNDLE_INCLUDE = 'Your bundle includes'
export const BUNDLE_TEXT = 'Click on a product to edit your bundle'
export const VALIDATION_PLEASE_COMPLETE_THIS_FIELD =
  'Please compelte this field'
export const GENERAL_ENGRAVING = 'Engraving'
export const GENERAL_ENGRAVING_PERSONALIZE_BOTTLE =
  'Personalize your bottle for only'
export const GENERAL_SEARCH_BRAND = 'Search Brand'
export const GENERAL_BRAND = 'Brand'
export const GENERAL_FILTER_TITLE = 'Filters'
export const GENERAL_SORT_FILTER_TITLE = 'Filter'
export const PRODUCT_FILTER = 'Product filters'
export const BTN_CLEAR_ALL = 'Reset'
export const TITLE_PRODUCTS = 'Products'
export const PRICEMATCH_WEBSITE_NAME =
  'Name of website you have found cheaper to*'
export const PRICEMATCH_PRODUCT_LINK =
  'Full direct link to product (copy/paste website URL)*'
export const PRICEMATCH_COST_OF_PRODUCT = 'Cost of product*'
export const PRICEMATCH_DELIVERY_COST = 'Delivery Cost*'
export const PRICEMATCH_TOTAL_COST = 'Total cost'
export const PRICEMATCH_USER_NAME = 'Name*'
export const PRICEMATCH_USER_EMAIL = 'Email*'
export const PRICEMATCH_USER_TELEPHONE = 'Telephone*'
export const PRICEMATCH_BEST_PRICE = 'We will match the best price'
export const PRICEMATCH_SEEN_IT_CHEAPER = 'Seen it cheaper?'
export const PRICEMATCH_ADDITIONAL_DETAILS = 'Additional details'
export const ADDITIONAL_CHARGE_TEXT = 'Additional Charge'
export const ADDITIONAL_FEE = '₹50'
export const VALIDATION_ENTER_CORRECT_URL = 'Enter correct url!'
export const VALIDATION_ENTER_PRODUCT_LINK = 'Please enter a product link'
export const VALIDATION_ENTER_WEBSITE_LINK = 'Please enter website link'
export const BTN_SUBMIT = 'Submit'
export const BTN_SUBMIT_REVIEW = 'Submit Review'
export const BTN_NOTIFY_ME = 'Notify me'
export const BTN_PRE_ORDER = 'Pre-order'
export const BTN_ADD_TO_WISHLIST = 'Add to wishlist'
export const ALERT_SUCCESS_WISHLIST_MESSAGE = 'Item was added in your wishlist'
export const PRODUCT_SPECIFICATION = 'Specifications'
export const GENERAL_NOT_AVAILABLE = 'Not Available'
export const GENERAL_CARE_TITLE = 'Care'
export const GENERAL_CARE_TEXT =
  'This is a limited edition production run. Printing starts when the drop ends.'
export const GENERAL_DETAILS = 'Details'
export const GENERAL_DETAILS_TEXT =
  'This is a limited edition production run. Printing starts when the drop ends. Reminder: Bad Boys For Life. Shipping may take 10+ days due to COVID-19.'
export const BTN_SEE_EVERYTHING = 'See everything'
export const GENERAL_SORT = 'Sort By'
export const CHOOSE_A_COLOR = 'Choose a color'
export const ITEM_TYPE_ADDON = 'ADDON'
export const YOUTUBE_VIDEO_PLAYER = 'YouTube video player'
export const GENERAL_REFERENCE = 'Ref'
export const GENERAL_PRICE_LABEL_RRP = 'RRP'
export const BTN_ADD_TO_FAVORITES = 'Add to favorites'
export const GENERAL_REVIEWS = 'Reviews'
export const GENERAL_REVIEW_OUT_OF_FIVE = 'out of 5 stars'
export const ERROR_WOOPS_SOMETHING_WENT_WRONG = 'Woops!, Something went wrong'
export const REVIEW_TITLE = 'Review title'
export const REVIEW_COMMENT = 'Type Your Comment'
export const MESSAGE_CHARACTERS_LEFT = 'Characters left'
export const POST_YOUR_REVIEW = 'Post your review'
export const VARIANT_SWATCH = 'Variant Swatch'
export const GENERAL_THANK_YOU = 'Thank you!'
export const GENERAL_ORDER_FAILED = 'Order Failed'
export const GENERAL_ON_THE_WAY = "It's on the way!"
export const GENERAL_YOUR_ORDER = ' Your order'
export const GENERAL_PAYMENT_FOR_YOUR_ORDER = 'Payment for your order'
export const GENERAL_PAYMENT_NOT_PROCESSED = 'could not be processed.'
export const GENERAL_ORDER_WILL_BE_WITH_YOU_SOON = 'will be with you soon.'
export const GENERAL_ITEMS = 'Items'
export const GENERAL_QUANTITY = 'Quantity'
export const GENERAL_PRICE = 'Price'
export const YOUR_INFORMATION = 'Your information'
export const GENERAL_ADDRESSES = 'Addresses'
export const GENERAL_SHIPPING_ADDRESS = 'Shipping address'
export const GENERAL_BILLING_ADDRESS = 'Billing address'
export const GENERAL_DELIVERED_BY = 'Delivered by'
export const GENERAL_SUMMARY = 'Summary'
export const VALIDATION_YOU_ARE_ALREADY_LOGGED_IN = "You're already logged in"
export const VALIDATION_NO_ACCOUNT_FOUND =
  'No account has been found with this email/password'
export const VALIDATION_ENTER_A_VALID_EMAIL = 'Please enter a valid email'
export const VALIDATION_EMAIL_ALREADY_IN_USE = 'This email is already in use'
export const BTN_REGISTER_FOR_FREE = 'Register for free'
export const LOADER_LOADING = 'Loading...'
export const ERROR_PAGE_NOT_FOUND = 'Not Found'
export const ERROR_PAGE_NOT_FOUND_MESSAGE =
  "The requested page doesn't exist or you don't have access to it."
export const BTN_CHECKOUT_NOW = 'Checkout now'
export const MESSAGE_NO_ORDER_FOUND = 'No orders found'
export const MESSAGE_NO_ORDER_FOUND_TEXT =
  'Biscuit oat cake wafer icing ice cream tiramisu pudding cupcake.'
export const GENERAL_ENTER_POSTCODE = 'Enter postcode'
export const GENERAL_ADDRESS_FINDER = 'Address Finder'
export const LOADING_YOUR_ORDERS = 'Loading your order'
export const NO_ORDER_PROVIDED = 'Woops! No order provided'
export const ENTER_ADDRESS_MANUALY = 'Enter address manually'
export const BTN_FIND = 'Find'
export const BTN_SAVE = 'Save'
export const BTN_BACK_TO_HOME = 'Back to Home'
export const IMG_PLACEHOLDER =
  'https://img.damensch.com/products/imageplaceholder.jpg'
export const RESULTS = 'results'
export const SHOP_BY_CATEGORY = 'Shop by Category'
export const SHOP_BY_COLLECTION = 'Shop by Collection'
export const GENERAL_COLOUR = 'Colour'
export const GENERAL_SIZE = 'Size'
export const SHOP_BY_LIFESTYLRE = 'Shop by Lifestyle'
export const PRODUCTS_AVAILABLE = 'Products available'
export const PRODUCT_IN_STOCK = 'In Stock'
export const PRODUCT_OUT_OF_STOCK = 'Out Of Stock'
export const PRODUCT_AVAILABILITY = 'Availability'
export const SLUG_TYPE_MANUFACTURER = 'Manufacturer'
export const GENERAL_SHOWING = 'Showing'
export const GENERAL_TOTAL_SAVINGS = 'Total Savings'
export const BAG_EMPTY_MESSAGE = "There's nothing in your cart. Yet!"
export const BAG_EMPTY_TEXT = 'Explore more and shop unbelievable products'
export const BTN_START_SHOPPING = 'Start Shopping'
export const GENERAL_FREE_GIFT_MESSAGE = "You're Eligible for a Free Gift"
export const GENERAL_REWARD_MESSAGE = 'Reward for shopping above'
export const BAG_FREE_GIFT = 'Select Gift'
export const BAG_DISCOUNT_MESSAGE = 'with Discounts, Free Gift and APP25 coupon'
export const CURRENCY_SYMBOL_RUPEE = '₹'
export const GENERAL_PRICE_DETAIL = 'Price Details'
export const GENERAL_PRICE_INC_TAX = 'Prices are inclusive of all taxes'
export const DELIVERY_AT = 'Delivery at'
export const ADDRESS_TYPE_HOME = 'Home'
export const ADDRESS_TYPE_OFFICE = 'Office'
export const ADDRESS_TYPE_OTHER = 'Other'
export const PERFECT_FOR = 'Perfect for'
export const FABRIC_CARE = 'Fabric care'
export const WASH_CARE = 'Wash care'
export const COLLAR = 'Collar'
export const QUICK_VIEW = 'Quick View'

// Alert Ribbon Messages
export const NETWORK_ERR = 'Network Error. Please Try Again.'
//PDP
export const INSUFFICIENT_STOCK = 'Insufficient stock for add to cart'
export const PROD_ADDED = 'Added to Bag'
export const PROD_ADDED_SUCCESSFULLY = 'Product Added Successfully'
export const REV_SUB = 'Review Submitted'
export const BAG_UPDATED = 'Bag Updated Successfully'
//profile-addres
export const NEW_ADDRESS = 'Address Added'
export const DUPLICATE_ADDRESS = 'Address Already Exists!'
export const ADDRESS_DEL = 'Address Deleted'
export const ADDRESS_UPDATE = 'Address Updated'
export const MOVED_TO_BAG = 'Moved to Bag'
export const PROFILE_UPDATED = 'Profile Updated'
//profile-logout
export const LOGOUT = 'Logout Successful'
//profile-payment
export const PAY_DELETED = 'Payment Method Removed'
export const PAY_SAVED = 'Payment Method Saved'
//profile-orders
export const ITEM_CANCELLED = 'Item Cancelled Successfully'
export const ORDER_CANCELLED = 'Order Cancelled Successfully'
export const SUBSCRIPTION_SUCCESS =
  'Email Registered Successfully for Newsletter, offers and promotions.'
//homepage
export const INVALID_OTP = 'OTP is Not Valid'
export const LOGIN = 'Login Successful'
//plp
export const ADDED_TO_WISH = 'Added to Wishlist'
export const MOVED_TO_WISH = 'Moved To Wishlist'
//pdp
export const GENERAL_CHANGE_SIZE = 'Change Size'
export const GENERAL_SELECT_SIZE = 'Select Size'

// Cart page
export const OTP_ERROR = 'Enter a Valid OTP'
export const ADD_FROM_WISHLIST = 'Add From Your Wishlist'
export const CART_TEXT = 'CART'
export const FREE_SHIPPING = 'FREE'
export const SHOP_FOR = 'Shop for'
export const MORE_TO_AVOID = 'more to avoid'
export const SHIPPING_FEE = 'shipping fee'
export const MIN_SHOPPING_COST = 600
export const YAY = 'Yay!'
export const NO_SHIPPING_FEE = 'No shipping fee'
export const ON_THIS_ORDER = 'on this order'
export const STATIC_DELIVERY_FEE = 50

// Footer
export const GENERAL_REGISTERED_ADDRESS = 'Registered address:'
export const REGISTERED_ADDRESS_FOOTER_PREVIOUS =
  'Damensch Apparel Pvt Ltd, 3rd Floor, 68-299-612/1, Attic Space-Lotus, 4th Block, Kormangala, Bengaluru, Karnataka, India 560034'
// export const REGISTERED_ADDRESS_FOOTER = '2, JB House No 110, JB House, Industrial Layout 4th Cross 5th Block Koramangala, Bengaluru, Bengaluru Urban, Karnataka, 560095'
export const REGISTERED_ADDRESS_FOOTER =
  '2nd Floor, JB House, 4th Cross, 5th Block, 110, Koramangala Industrial Layout, Bengaluru, Karnataka 560095'
export const CIN_NUMBER = 'U74995KA2018PTC150647' //U74995Ka2018Ptc150647

// Refer and Earn
export const REFERAL_AMOUNT = 1000

// Pick Pay Go
export const BUY_FROM_STORE =
  'Buy from store at online prices in 3 simple steps'
export const PICK_TEXT =
  'Scan Barcode on the packaging to add it to your shopping bag'
export const PAY_TEXT = 'Pay Online with your favourite payment method'
// export const GO_TEXT = 'Scan Barcode on the packaging to add it to your shopping bag'
export const GO_TEXT = `Walk out from the store & enjoy your DaMENSCH comfort!`
export const START_SHOPPING_TEXT = 'Scan Barcode to Start Shopping'
export const STORE_CHANNEL_MESSAGE =
  'Scan the Product Barcode to add it to your bag'
export const SCAN_CODE_MESSAGE = 'Scan the Product Barcode'
export const BAR_CODE_PLACEHOLDER = 'Enter the 13-Digit Product Barcode'
export const SHOW_TO_SHOPKEEPER_MESSAGE = `Show this to the shopkeeper and collect your
product(s)`
export const DID_SHOPPING_WELL = 'Did your shopping go well?'
export const RATE_US_GENERAL =
  'Rate & give us feedback to improve your shopping experience'
// export const ENTER_VALID_PRODUCT_CODE = 'The barcode number seems wrong. Please verify.';
export const ENTER_VALID_PRODUCT_CODE = 'This product barcode seems to be incorrect. Please verify again or speak with the store incharge.'
export const ENTER_VALID_PRODUCT_ITEM_CODE = 'This product itemcode seems to be incorrect. Please verify again or speak with the store incharge.'
export const ALREADY_EXIST_ITEM_CODE = 'This product itemcode already added in basket. Please verify again or speak with the store incharge.'
export const ENTER_VALID_BAR_CODE = `Please make sure you're scanning the correct barcode from a DaMENSCH product`
export const ENTER_VALID_ITEM_CODE = `Please make sure you're scanning the correct itemcode from a DaMENSCH product`
export const FETCHING_MSG = 'Fetching the best online prices for this product'
export const PHYSICAL_HOLDING_MESSAGE =
  'Make sure you are physically holding the product(s)'
export const SCAN_PRODUCT_BARCODE_TEXT = 'Scan Product Barcode to add to bag'
export const SCAN_PRODUCT_ITEM_CODE_TEXT = 'Scan Product ItemCode to add to bag'
export const PROBLEM_SCANNING_MSG = 'Problem scanning itemcode? ↓'
export const FETCHING_BEST_PRICE_TEXT = 'Fetching the best prices online'
export const ADD_THIS_TO_BAG = 'Add this to bag?'
export const SAMPLE_BARCODE_MESSAGE =
  'Look for the 13-digit code near the barcode on the packaging (as indicated in the above image)'
export const CROSSED_LIMIT_FOR_PPG =
  'We only allow a limited numbers of PickPayGo Orders per-day and per-week, for each customer. Please buy directly by paying at the store, or try again after 1-2 days.'
export const PPG_ERROR_MODAL_HEADING = `Sorry! Your order can't be placed :(`

export const RETURN_REFUND_COD_DETAILS_VARIABLE = `You will receive refund once the returned item(s) are received & approved by our quality check team. <br />Since your order was 'Pay on Delivery', in order to receive the refund -- please fill your UPI ID below:`
export const RETURN_REFUND_PREPAID_DETAILS_VARIABLE = `You will receive the refund once the returned item(s) are received & approved by our quality check team. <br />Your refund will be received in the below payment mode:`
export const RETURN_REFUND_COD_DETAILS_FILLED_VARIABLE = `Since your order was 'Pay on Delivery', you will receive the refund in the below mentioned UPI ID, filled by you:`

export const CLEARANCE_TEXT_VARIABLE = 'a lot off'

// replacement return policy days
export const DAYS_500 = '500'
export const DAYS_30 = '30'
export const DAYS_15 = '15'

// localStorage const
export const SEARCH_ITEM_CLICKED_POSITION = 'searchItemClickedPosition'
