import { useCallback, useEffect, useState } from 'react';
import KwikPassScript from '@components/KwikPass/KwikPassScript'
import { EmptyGuid, FirebaseKeys, NEXT_AUTHENTICATE } from '@components/utils/constants';
import { setSessionItem } from '@components/utils/sessionStorage';
import { useRouter } from 'next/router';
import { LocalStorage } from '@components/utils/payment-constants';
import Cookies from 'js-cookie';
import { setItem } from '@components/utils/localStorage';
import { getDeviceTypeString } from '@commerce/utils/use-device'
import { v4 as uuid } from 'uuid'
import { MO_ENGAGE_EVENT } from 'hooks/moengage_event.modal'
import { useMoEngageEvent } from 'hooks/useMoengageEvent'
import { asyncHandler } from '@components/account/Address/helper';
import axios from 'axios';
import { getSHA256Hash, getEncryptedNumber } from '@framework/utils/cipher';
import { GA_EVENT } from 'hooks/ga_event.modal';
import { recordGA4Event } from '@components/services/analytics/ga4';
import { Cookie } from '@framework/utils/constants';
import { getUserNumber } from '@components/account/loginHelper';
import { removeCookie, setCookie } from '@framework/utils';
import { getRemoteConfigAsString } from '@framework/utils/app-util';
const { getAddress } = asyncHandler()

export default function KwikPass() {
    const router = useRouter()
    const trackMoEngageEvent = useMoEngageEvent()
    const [scriptLoaded, setScriptLoaded] = useState(false)
    const onLoaded = useCallback(() => {
        setScriptLoaded(true)
    }, [])

    const handleGAEventsForLoginModal = (loginResult: any, response: any) => {
        const loginWrapper = document?.getElementById('d2c-pass');
        if (loginWrapper) {
            const mobileRSA = getEncryptedNumber(loginResult?.username)
            setCookie(Cookie.Key.LOGIN_POPUP, 'true');
            const gaPayload = {
                previous_page: 'Cart',
                address_source: response?.length ? 'Damensch' : 'kwikpass',
                bc_login_status: false,
                kp_no_browser: 'not_found',
                user_type: response?.length ? 'existing' : 'new',
                login_popup: 'kp',
                mobile_number: mobileRSA,
                checkout_flow: getRemoteConfigAsString(FirebaseKeys.CHECKOUT_FLOW),
            };
    
            // GA event for OTP request
            recordGA4Event(window, GA_EVENT.OTP_REQUEST, {
                bc_user_token: 'not_exist',
                ...gaPayload
            });
    
            // GA event for OTP submit
            recordGA4Event(window, GA_EVENT.OTP_SUBMIT, {
                bc_user_token: 'exist',
                ...gaPayload
            });
        } else {
            setCookie(Cookie.Key.LOGIN_POPUP, 'false');
        }
    };

    const handleKwikpassLogin = (event: any) => {
        if(event?.detail?.['gk-access-token']) setItem(LocalStorage.Key.GOKWIK_TOKEN, event?.detail?.['gk-access-token'])
    };

    const handleMerchantLogin = async (event: any) => {
        if(event?.detail?.loginToken){
            const { data: loginResult }: any = await axios.post(NEXT_AUTHENTICATE, {
             data: { password: event?.detail?.loginToken, authType: 3 }
            })
            if(loginResult?.userId && loginResult?.userId !== EmptyGuid && loginResult?.username) {
                const {userToken, ...rest } = loginResult
                setItem('user', {...rest})
                if(userToken && userToken?.access_token){
                    localStorage.setItem(LocalStorage.Key.userEncryptedDetails, userToken?.access_token)
                    setCookie(LocalStorage.Key.userEncryptedDetails, userToken?.access_token)
                }

                trackMoEngageEvent(
                    MO_ENGAGE_EVENT.LOGIN,
                    {
                    device: getDeviceTypeString(),
                    first_login:'',
                    status: "sent",
                    mobile_number: loginResult?.username,
                    uuid : uuid()
                    },
                    window
                )

                // set moengage user attributes
                if (window?.Moengage) {
                    const userMobile = getUserNumber(loginResult)
                    window?.Moengage.update_unique_user_id(userMobile)
                    window?.Moengage.add_unique_user_id(userMobile)
                    window?.Moengage.add_first_name(loginResult.firstName)
                    window?.Moengage.add_last_name(loginResult.lastName)
                    window?.Moengage.add_email(loginResult.email)
                    window?.Moengage.add_mobile(userMobile)
                    window?.Moengage.add_user_name(`${loginResult.firstName} ${loginResult.lastName}`)
                    window?.Moengage.add_gender(loginResult.gender)
                    window?.Moengage.add_birthday(`${loginResult.dayOfBirth}/${loginResult.monthOfBirth}/${loginResult.yearOfBirth}`)
                }
    
                const response: any = await getAddress(loginResult?.userId);
                if(response?.length){
                    setSessionItem('isUserAlreadyExist', JSON.stringify(true))
                    setCookie(Cookie.Key.IS_EXISTING_USER, 'true')
                } else {
                    setSessionItem('isUserAlreadyExist', JSON.stringify(false))
                    setCookie(Cookie.Key.IS_EXISTING_USER, 'false')
                }
                handleGAEventsForLoginModal(loginResult, response);
                router.push('/checkout#kp_login')    
            }
        }
    };

    useEffect(() => {
        let scriptReadyEvent = new CustomEvent(Cookie.Key.IS_KP_SCRIPT_READY)
        setCookie(Cookie.Key.IS_KP_SCRIPT_READY, 'false');
        if (scriptLoaded) {
            window.addEventListener('user-loggedin', handleKwikpassLogin);
            window.addEventListener('user_loggedin_merchant', handleMerchantLogin);
            window.dispatchEvent(scriptReadyEvent)

            // Cleanup function
            return () => {
                window.removeEventListener('user-loggedin', handleKwikpassLogin);
                window.removeEventListener('user_loggedin_merchant', handleMerchantLogin);
                removeCookie(Cookie.Key.IS_KP_SCRIPT_READY)
            };
        }
    }, [scriptLoaded]);

    return (
        <KwikPassScript onLoaded={onLoaded} />
    );
}